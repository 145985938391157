<template>
  <div>
    <d-body-top-bar
      :title="$t('views.shop.title')"
      :full-body="false"
    />
    <product-stock/>
  </div>
</template>
<script>
export default {
  components: {
    ProductStock: () => import('./ProductStock'),
  },
}
</script>
